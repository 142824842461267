<template>
    <el-menu default-active="1-4-1" class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose"
        :collapse="isCollapse" background-color="#2c3e50" text-color="#ffffff" active-text-color="#1890ff">

        <div class="container">
            <!-- 使用 transition 组件包裹内容 -->
            <transition name="slide-fade">
                <div v-if="isCollapse" key="collapse" class="collapse-content">
                    <img src="../assets/log/image_copy.png" width="35px" height="30px" style="margin-left: 5px;">
                </div>
                <div v-else key="expand" class="expand-content">
                    <img src="../assets/log/image_copy.png" width="35px" height="30px" style="margin-left: 15px;">
                    <h3>提交服后台系统</h3>
                </div>
            </transition>
        </div>

        <!-- 无子菜单的菜单项 -->
        <el-menu-item v-for="item in noChildren" :key="item.name" :index="item.name" @click="clickMenu(item)">
            <i :class="`el-icon-${item.icon}`"></i>
            <span slot="title">{{ item.label }}</span>
        </el-menu-item>

        <!-- 有子菜单的菜单项 -->
        <el-submenu v-for="item in hasChildren" :key="item.label" :index="item.label">
            <template slot="title">
                <i :class="`el-icon-${item.icon}`"></i>
                <span>{{ item.label }}</span>
            </template>
            <el-menu-item v-for="subItem in item.children" :key="subItem.path" :index="subItem.path"
                @click="clickMenu(subItem)">
                <i :class="`el-icon-${subItem.icon}`"></i>
                <span slot="title">&nbsp;{{ subItem.label }}</span>
            </el-menu-item>
        </el-submenu>
    </el-menu>
</template>



<script>
import Cookie from 'js-cookie'
export default {
    data() {
        return {};
    },
    methods: {
        handleOpen(key, keyPath) {
            console.log(key, keyPath);
        },
        handleClose(key, keyPath) {
            console.log(key, keyPath);
        },
        // 点击菜单
        clickMenu(item) {
            // console.log(item)
            // 当页面的路由与跳转的路由不一致才允许跳转
            if (this.$route.path !== item.path && !(this.$route.path === "/home" && (item.path === "/"))) {
                this.$router.push(item.path);
            }
            // 这里指需要传入触发的事件
            this.$store.commit('selectMenu', item)
        }
    },
    computed: {
        // 没有子菜单
        noChildren() {
            return this.menuData.filter(item => !item.children)
        },
        // 有子菜单
        hasChildren() {
            return this.menuData.filter(item => item.children)
        },
        isCollapse() {
            return this.$store.state.tab.isCollapse
        },
        // 判断当前数据，如果缓存中没有，就去当前store中去获取
        menuData() {
            return JSON.parse(Cookie.get("menu")) || this.$store.state.tab.menu
        }
    }
}
</script>

<style lang="less" scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 100%;
}

.container {
    display: flex;
    align-items: center;
    padding: 10px;
    color: #fff;
}

.collapse-content,
.expand-content {
    display: flex;
    align-items: center;
}

.expand-content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

img {
    margin-right: 10px;
    margin-top: 8px;
    margin-left: 5px;
}

/* 动画样式 */
.slide-fade-enter-active,
.slide-fade-leave-active {
    transition: all 0.3s ease;
}

.slide-fade-enter,
.slide-fade-leave-to

/* .slide-fade-leave-active in <2.1.8 */
    {
    opacity: 0;
    transform: translateX(20px);
}

/* 设置包含子菜单的父菜单项的背景颜色 */
.el-menu-vertical-demo .el-submenu .el-submenu__title {
    background-color: #1f2d3d !important;
}

/* 设置展开的子菜单项的背景颜色 */
.el-menu-vertical-demo .el-submenu .el-menu-item {
    background-color: #1f2d3d !important;
}


.el-menu {
    height: 100vh;
    border-right: none;

    h3 {
        color: white;
        text-align: center;
        line-height: 50px;
        font-weight: 600;
        font-size: 15px;
        // letter-spacing: 1px;
        font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
        margin-bottom: -10px;
    }
}
</style>
