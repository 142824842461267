<template>
    <el-row>
        <!-- 左侧 -->
        <el-col :span="8">
            <el-card class="box-card">
                <div class="user">
                    <img @click="openModal" src="../assets/log/centre_image.png" alt="log">

                    <div class="userinfo">
                        <p class="name">admin</p>
                        <p class="access">超级管理员</p>
                    </div>
                </div>
                <div class="login-info">
                    <p>系统登录时间: <span>{{ currentTime }}</span></p>
                    <p>系统登录地址: <span>{{ currentPosition }}</span></p>
                </div>
            </el-card>
        </el-col>

        <!-- 右侧 -->
        <el-col :span="16">
            <el-card class="box-right">
                <div slot="header" class="clearfix">
                    <span>基本资料</span>
                    <div class="tui">
                        <el-page-header @back="goBack" content="个人中心">
                        </el-page-header>
                    </div>
                </div>

                <div class="updata">
                    <el-form label-width="90px" ref="ruleForm" :model="ruleForm" :rules="rules">
                        <el-form-item label="旧密码" prop="oldPassword">
                            <el-input v-model="ruleForm.oldPassword" placeholder="请输入旧密码" show-password></el-input>
                        </el-form-item>
                        <el-form-item label="新密码" prop="newPassword">
                            <el-input v-model="ruleForm.newPassword" placeholder="请输入新密码" show-password></el-input>
                        </el-form-item>
                        <el-form-item label="确认密码" prop="confirmPassword">
                            <el-input v-model="ruleForm.confirmPassword" placeholder="请确认密码" show-password></el-input>
                        </el-form-item>

                        <el-form-item>
                            <el-button type="primary" @click="submitForm()">保存</el-button>
                            <el-button @click="resetForm()">重置</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </el-card>
        </el-col>
    </el-row>
</template>

<script>
import { chanelPass } from '@/api/channel'
export default {
    data() {
        return {
            currentTime: new Date().toLocaleString(),
            currentPosition: '正在获取位置信息...',
            ruleForm: {
                oldPassword: '',
                newPassword: '',
                confirmPassword: ''
            },
            rules: {
                oldPassword: [
                    { required: true, message: '旧密码不能为空', trigger: 'blur' }
                ],
                newPassword: [
                    { required: true, message: '请输入新密码', trigger: 'blur' },
                    { pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/, message: '密码必须包含字母和数字,且至少8位', trigger: 'blur' }
                ],
                confirmPassword: [
                    { required: true, message: '确认密码不能为空', trigger: 'blur' },
                    { validator: this.validatePasswordMatch, trigger: 'blur' }
                ]
            }
        };
    },
    methods: {
        openModal(val) {
            /** 点击图片就进行放大展示，点击任意地方进行还原 */
            // alert("sdfsd")
            this.$modal.show('../assets/log/centre_image.png');
            this.$modal.show(val);
        },
        goBack() {
            this.$router.back();
        },
        resetForm() {
            this.$refs.ruleForm.resetFields();
        },
        submitForm() {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    // 实际密码更新逻辑
                    this.updatePassword();
                } else {
                    console.log('表单验证失败');
                }
            });
        },
        validatePasswordMatch(rule, value, callback) {
            if (value !== this.ruleForm.newPassword) {
                callback(new Error('两次输入的密码不一致'));
            } else {
                callback();
            }
        },
        async updatePassword() {
            // 发送请求到服务器更新密码
            const { oldPassword, newPassword } = this.ruleForm;
            const data = { oldPassword, newPassword };

            try {
                const response = await chanelPass(data);
                console.log(response);

                this.$message({
                    message: '密码更新成功',
                    type: 'success'
                });

                this.resetForm();
                this.$router.push('/login');
                localStorage.clear();
                localStorage.setItem('token', '');
                this.$router.go(0);

            } catch (error) {
                // 处理请求失败的情况
                let errorMessage = '更新密码失败,请稍后重试';
                if (error.response && error.response.data.message) {
                    errorMessage = error.response.data.message;
                }
                this.$message.error(errorMessage);
            }
        }
    },
    created() {
        setInterval(() => {
            this.currentTime = new Date().toLocaleString().replace(/\//g, '-');
        }, 1000);
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                const latitude = position.coords.latitude;
                const longitude = position.coords.longitude;
                this.currentPosition = `纬度：${latitude}, 经度：${longitude}`;
            }, (error) => {
                console.error(error);
                this.currentPosition = '获取位置信息失败';
            });
        } else {
            this.currentPosition = '浏览器不支持获取地理位置信息';
        }
    }
}
</script>



<style lang="less" scoped>
.box-card {
    height: 350px;
}

.user {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 2.5px solid #ccc;

    img {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        margin-right: 40px;
    }

    .userinfo {
        .name {
            font-size: 25px;
            margin-bottom: 15px;
        }

        .access {
            color: gray;
        }
    }
}

.login-info {
    p {
        margin-bottom: 15px;

        span {
            margin-left: 35px;
        }
    }
}

.box-right {
    width: calc(100% - 320px);
    height: 350px;
    margin-left: 30px;

    .clearfix {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .tui {
            margin-left: auto;
        }
    }
}
</style>