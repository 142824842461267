<template>
    <div class="header-container">
        <!-- 左边 -->
        <div class="l-content">
            <el-button type="text" style="margin-right:20px" @click="handleMenu" icon="el-icon-s-fold"></el-button>
            <!-- 面包屑 -->
            <transition name="breadcrumb-item" mode="out-in">
                <el-breadcrumb separator="/" v-if="breadcrumbs.length > 0"
                    :key="breadcrumbs[breadcrumbs.length - 1].path">
                    <el-breadcrumb-item v-for="item in breadcrumbs" :key="item.path" :to="{ path: item.path }"
                        :class="{ 'active': isCurrentPath(item.path), 'animate': isChannelList(item.path) }">
                        {{ item.label }}
                    </el-breadcrumb-item>
                </el-breadcrumb>
            </transition>
        </div>

        <!-- 右边 -->
        <div class="r-content">
            <el-dropdown @command="handleCommand">
                <span class="el-dropdown-link">
                    <img class="user" src="../assets/log/title_image.png" alt="User Icon" />
                </span>
                <template #dropdown>
                    <el-dropdown-menu>
                        <el-dropdown-item command="centre">个人中心</el-dropdown-item>
                        <!-- <el-dropdown-item disabled>页面布局</el-dropdown-item> -->
                        <hr>
                        <el-dropdown-item command="logout">退出登录</el-dropdown-item>
                    </el-dropdown-menu>
                </template>
            </el-dropdown>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import Cookie from 'js-cookie';

export default {
    name: 'CommonHeader',
    components: {},
    data() {
        return {
            breadcrumbs: [],
            // 使用局部变量存储当前的状态，方便操作
            localTheme: {
                themeStyle: 'dark',
                themeColor: '#409eff',
                topNav: false,
                tagsView: true,
                fixedHeader: false,
                showLogo: true,
                dynamicTitle: false

            }
        };
    },
    watch: {
        $route(to) {
            this.setBreadcrumbs(to.meta.breadcrumb);
        }
    },
    created() {
        const storedBreadcrumbs = localStorage.getItem('breadcrumbs');
        if (storedBreadcrumbs) {
            this.breadcrumbs = JSON.parse(storedBreadcrumbs);
        } else {
            this.setBreadcrumbs(this.$route.meta.breadcrumb);
        }
    },
    methods: {
        isCurrentPath(path) {
            return this.$route.path === path;
        },
        isChannelList(path) {
            return path === '/channel-list'; // 替换为实际的路径
        },
        handleMenu() {
            this.$store.commit('collapseMenu');
        },
        handleCommand(command) {
            switch (command) {
                case 'logout':
                    this.logout();
                    break;
                case 'centre':
                    this.navigateToCentre();
                    break;
                case 'settings':
                    this.$refs.settingsPopover.showPopper = true; // 显示 popover
                    break;
                default:
                    console.log(`未知命令：${command}`);
            }
        },
        logout() {
            this.$confirm('确定注销并退出登录吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                Cookie.remove('token');
                Cookie.remove('menu');
                this.$router.push('/login');
            }).catch(() => { });
        },
        navigateToCentre() {
            this.$router.push('/centre');
        },
        setBreadcrumbs(breadcrumbs) {
            this.breadcrumbs = breadcrumbs;
            localStorage.setItem('breadcrumbs', JSON.stringify(breadcrumbs));
        },
        isCurrentPath(path) {
            return this.$route.path === path;
        },
        // Popover 展示时的操作
        onShowSettingsPopover() {
            console.log('Settings popover shown');
            // 从 Vuex store 中获取设置，并更新到当前组件的 data 中
            // 使用 Object.assign 合并对象，确保不会覆盖 Vuex store 中的其他属性

            const theme = this.$store.state.theme || {};
            Object.assign(this.$data, theme); // 使用 Object.assign 合并对象，确保不会覆盖 Vuex store 中的其他属性
            console.log(this.$data); // 输出合并后的数据，确保数据正确            
        },
        // Popover 隐藏时的操作
        onHideSettingsPopover() {
            console.log('Settings popover hidden');
            // this.saveSettings(); // 调用保存设置的方法
        },
        // 重置设置的操作
        resetSettings() {
            console.log('Settings reset');
            this.localTheme = {
                themeStyle: 'dark',
                themeColor: '#409eff',
                topNav: false,
                tagsView: true,
                fixedHeader: false,
                showLogo: true,
                dynamicTitle: false
            };
        },
        // 保存设置到 Vuex store
        saveSettings() {
            this.$store.dispatch('theme/updateTheme', this.localTheme);
            this.$message.success('保存成功');
            console.log('Settings saved:', this.$store.getters['theme/getTheme']);
        }
    },
    computed: {
        ...mapState({
            tags: state => state.tab.tabsList
        }),
        ...mapState('theme', ['theme'])
    }
};
</script>

<style lang="less" scoped>
/* 面包屑项的动画类 */
.animate-enter-active ,
.animate-leave-active {
    animation: fadeIn 0.5s;
   
}

@keyframes fadeIn {
    from {
        opacity: 1;
        transform: translateY(10px);
    }

    to {
        opacity: 1;
        transform: translateY(10px);
    }
}


.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    height: 60px;
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

    .l-content {
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    .r-content {
        .el-dropdown-link {
            cursor: pointer;

            .user {
                width: 32px;
                height: 32px;
                border-radius: 30%;
                margin-right: 10px;
            }
        }
    }
}
</style>