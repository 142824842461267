<template>
    <div class="backgroundImg">
        <div ref="vantaRef" style="width: 100%; height: 100vh"> </div>
        <el-form label-width="70px" :inline="true" :model="form" class="login-container" :rules="rules" ref="form">
            <h3 class="login_title">xx后台登录界面</h3>

            <el-form-item label="账号" prop="username">
                <el-input v-model="form.username" placeholder="请输入账号" prefix-icon="el-icon-user" autocomplete="off">
                </el-input>
            </el-form-item>

            <el-form-item label="密码" prop="password">
                <el-input show-password type="password" v-model="form.password" placeholder="请输入密码"
                    prefix-icon="el-icon-lock" autocomplete="off">
                </el-input>
            </el-form-item>

            <el-form-item label="验证码" prop="code">
                <el-input v-model="form.code" placeholder="验证码" prefix-icon="el-icon-success" style="width: 100px;">
                </el-input>
                <el-button v-if="!picUrl" @click="getPicCode" type="primary" style="width: 100px;margin-left:2px"
                    @keyup.enter="submit(form)"> 获取验证码</el-button>
                <template v-else>
                    <img v-if="picUrl" :src="picUrl" @click="getPicCode" alt="">
                </template>
            </el-form-item>


            <el-form-item class="isChecked">
                <!-- `checked` 为 true 或 false -->
                <el-checkbox v-model="form.checked" @change="handleRememberPwd" class="remeberPwd">记住密码</el-checkbox>
            </el-form-item>

            <el-form-item>
                <el-button @click="submit" style="margin-left: 80px; margin-top: -2px ;" type="primary">
                    登录
                </el-button>
                <el-button @click="cancel" style="margin-left:40px; margin-top: -2px;" type="danger">
                    重置
                </el-button>
            </el-form-item>

        </el-form>
    </div>
</template>

<script>
import Cookie from 'js-cookie'
import { getMenu } from '../api'
export default {
    data() {
        return {
            picUrl: '',// 存储请求渲染的图片地址
            picKey: '',
            loading: false, // 默认加载状态为 false      
            forgetFormVisible: false,
            form: {
                username: '',
                password: '',
                code: ''
            },
            rules: {
                username: [
                    { required: true, message: '请输入账号', trigger: 'blur' }
                ],
                password: [
                    { required: true, message: '请输入密码', trigger: 'blur' }
                ],
            }
        }
    },
    methods: {
        getPicCode(){
        },
        handleRememberPwd(checked) {
            if (checked) {
                // 执行记住密码的逻辑，例如将密码保存到 localStorage 中
                localStorage.setItem('username', this.form.username);
                localStorage.setItem('password', this.form.password);
            } else {
                // 执行取消记住密码的逻辑，例如清除 localStorage 中的密码信息
                localStorage.removeItem('username');
                localStorage.removeItem('password');
            }
        },
        cancel() {
            this.$refs.form.resetFields();
        },
        submit() {
            // this.$loads = true()
            this.$refs.form.validate((valid) => {
                if (valid) {
                    getMenu(this.form).then(({ data }) => {
                        console.log(data)
                        if (data.code === 20000) {
                            // 将token信息存入cookie用于不同页面间的通信
                            Cookie.set('token', data.data.token)

                            // 获取菜单的数据，存放store中
                            this.$store.commit("setMenu", data.data.menu)
                            this.$store.commit("addMenu", this.$router)

                            // 跳转到首页
                            this.$router.push("/home")
                        } else {
                            this.$message.error(data.data.message)
                        }
                    })
                }

            })
        },
        retrievePWD() {
            this.$router.push({ path: '/forget' });
        }
    }
}
</script>


<style lang="less" scoped>
.backgroundImg {
    position: relative;
    background: url('../assets/log/login_image.png') no-repeat;
    background-size: cover;
    height: 100vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    //  margin-left: 40px;

    .login-container {
        width: 400px;
        border: 1px solid #eaeaea;
        margin-bottom: 400px;
        padding: 35px 35px 15px 35px;
        background-color: #efefef;
        border-radius: 15px;
        box-shadow: 0 0 25px #efefef;
        box-sizing: border-box;
        z-index: 999;
        position: absolute;
        top: 30%;
        right: 5%;

        .el-input {
            width: 200px;

        }

        .isChecked {
            margin-left: 30px;
        }

        /* 改变input框背景颜色 */
        /deep/ .el-input__inner {
            background-color: #444444;
            color: white;

        }

        .login_title {
            text-align: center;
            // margin-bottom: 15px;
            // margin-top: -5px;
            color: #505458;
            margin: 0 auto 25px auto;
        }

    }
}
</style>
