import axios from "axios";
import { Message } from 'element-ui'; // 假设你使用的是 Element UI 的消息提示

const http = axios.create({
    // 通用请求的地址前缀
    baseURL: 'http://gateway2.aypaypro.com:8300/',
    // baseURL: 'http://18.163.180.43:8300/',
    // baseURL: 'http://localhost:8300/',
    // baseURL: 'api', // 代理服务器地址
    timeout: 10000,
})
// 服务端返回的数据格式

http.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
http.defaults.withCredentials = true


// 请求拦截器
http.interceptors.request.use(
    function (config) {
        // 在发送请求之前做些什么
        // 添加请求头或其他配置
        config.headers['Content-Type'] = 'application/json'; // 默认 Content-Type
        return config;
    },
    function (error) {
        // 对请求错误做些什么
        return Promise.reject(error);
    }
);


// 响应拦截器
http.interceptors.response.use(
    function (response) {
        // 2xx 范围内的状态码都会触发该函数。
        // 对响应数据做点什么
        return response;
    },
    function (error) {
        let errorMessage = '请求发生错误，请稍后再试。';
        if (error.response) {
            switch (error.response.status) {
                case 400:
                    errorMessage = '请求错误，请检查参数';
                    break;
                case 401:
                    errorMessage = '未授权，请重新登录';
                    break;
                case 403:
                    errorMessage = '禁止访问，请联系管理员';
                    break;
                case 404:
                    errorMessage = '请求资源不存在';
                    break;
                case 500:
                    errorMessage = '服务器内部错误，请稍后再试';
                    break;
                default:
                    errorMessage = '请求发生错误，请稍后再试';
            }
        }
        Message.error(errorMessage); // 显示错误信息
        return Promise.reject(error);
    }
);

// 请求工具函数
// const request = {
//     get(url, params) {
//         return http.get(url, { params });
//     },
//     post(url, data) {
//         return http.post(url, data);
//     },
//     put(url, data) {
//         return http.put(url, data);
//     },
//     delete(url, data) {
//         return http.delete(url, { data });
//     }
// };

export default http