import Mock from "mockjs";
import user from "./mockServeData/user";
import permission from "./mockServeData/permission";

// 用户列表的数据
Mock.mock('/api/user/add', 'post', user.createUser)
Mock.mock('/api/user/edit', 'post', user.updateUser)
Mock.mock('/api/user/del', 'post', user.deleteUser)
Mock.mock(/api\/user\/getUser/, user.getUserList)

// 用户登录信息
Mock.mock(/permission\/getMenu/, 'post', permission.getMenu)

//