import Mock from 'mockjs'
export default {
    getMenu: config => {
        const { username, password } = JSON.parse(config.body)
        // 先判断用户是否存在
        // 判断账号和密码是否对应
        if (username === 'admin' && password === '123456') {
            return {
                code: 20000,
                data: {
                    menu: [
                        {
                            path: '/home',
                            name: 'home',
                            label: '首页',
                            icon: 's-home',
                            url: 'Home/Home'
                        },
                        {
                            label: '系统配置',
                            icon: 's-tools',
                            children: [
                                // {
                                //     path: '/user',
                                //     name: 'user',
                                //     label: '测试数据',
                                //     icon: 'user',
                                // },
                                {
                                    path: '/role',
                                    name: 'role',
                                    label: '角色管理',
                                    icon: 'unlock',
                                },
                            ]
                        },
                        {
                            label: '渠道管理',
                            icon: 'thumb',
                            children: [
                                {
                                    path: '/channel',
                                    name: 'channel',
                                    label: '网关列表',
                                    icon: 'odometer',
                                }
                            ]
                        },
                        // {
                        //     label: '个人信息',
                        //     icon: 's-custom',
                        //     children: [
                        //         {
                        //             path: '/Centre',
                        //             name: 'Centre',
                        //             label: '个人中心',
                        //             icon: 's-check',
                        //         }
                        //     ]
                        // }
                    ],
                    token: Mock.Random.guid(),
                    message: '获取成功'
                }
            }
        } else {
            return {
                code: -999,
                data: {
                    message: '密码输入错误,请重新输入'
                }
            }
        }

    }
}